import React from 'react'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'

import { ITheme } from '@/shared/styles/MuiTheme'
import { WebsiteButtonStyle } from '@/shared/models/Website'
import { Paragraph2 } from '../Typography'
import { useClub } from 'contexts/club'
import Icon from '../Icon/Icon'


interface IStyleProps {
    buttonStyle: WebsiteButtonStyle;
}

const slugStyles = makeStyles((theme: ITheme) => createStyles({
    slug: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: ({ buttonStyle }: IStyleProps) => {
            return buttonStyle === WebsiteButtonStyle.SQUARE_CORNERS ? 0 : '4px'
        },
        padding: theme.spacing(1, 2),
        backgroundColor: theme.props.sectionMode.accent,
        [theme.breakpoints.down('sm')]: {
            height: '27px',
        }
    },
    iconSlug: {
        marginRight: theme.spacing(1),
        width: '16px',
        height: '16px',
        color: theme.props.sectionMode.text_accent,
        '& img, & svg, & i': {
            width: '16px',
            height: '16px',
        }
    }
}))

interface IProps {
    text: string | React.ReactNode;
    icon?: React.ReactNode;
    imgSrc?: string;
}

export function Slug ({ text, icon, imgSrc }: IProps): JSX.Element {
    const { website } = useClub()
    const theme: ITheme = useTheme()
    const classes = slugStyles({ buttonStyle: website?.button_style })

    function renderIcon() {
        return (
            <div className={classes.iconSlug}>
                {imgSrc ? <Icon src={imgSrc} hexColor={theme.props.sectionMode.text_accent} /> : icon}
            </div>
        )
    }
    
    return (
        <div>
            <div className={classes.slug}>
                {icon || imgSrc ? renderIcon() : ''}
                {text && <Paragraph2 isAccent>{text}</Paragraph2>}
            </div>
        </div>
    )
}
import React from 'react'

import MButton, { IButtonProps } from './MButton'


export default function IconButton({
    children,
    color = 'secondary',
    variant = 'outlined',
    ...rest
}: IButtonProps): JSX.Element {
    return (
        <MButton
            isIcon
            variant={variant}
            color={color}
            {...rest}
        >
            {children || null}
        </MButton>
    )
}
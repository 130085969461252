import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { ITheme } from '@/shared/styles/MuiTheme'
import { Box, BoxProps } from '@material-ui/core'

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        backgroundColor: theme.props.sectionMode.card,
        opacity: '0.5',
        mixBlendMode: 'normal',
        boxSizing: 'border-box',
        margin: 0,
        border: 0,
        height: '1px',
        width: '100%'
    },
    'large': {
        height: '6px',
    },
    'medium': {
        height: '4px',
    },
    'small': {
        height: '2px',
    },
}))

interface IProps {
    size?: 'small' | 'medium' | 'large';
}

export function Separator({
    size = 'small',
    ...boxProps
}: IProps & BoxProps): JSX.Element {
    const classes = useStyles()

    return (
        <Box {...boxProps}>
            <hr className={`${classes.root} ${classes[size]}`} />
        </Box>
    )
}